import './_dist/simple-lightbox.min.js'
import Swiper from'./_dist/swiper-bundle.min.js'

function setActiveLocation(mapContainer, activeButton) {
    let mapSvg = mapContainer.querySelector('svg');
    
    if(mapSvg) {
        let mapButtons = mapContainer.querySelectorAll('[data-location]');
        let locations = mapContainer.querySelector('#locations');
        let activeButtonSlug = activeButton ? activeButton.dataset.location : false;

        mapButtons.forEach(mapButton => {
            let slug = mapButton.dataset.location;

            if(activeButton) {
                if(mapButton === activeButton) {
                    mapButton.classList.add('is--active');
                    mapButton.classList.remove('is--disabled');
                } else {
                    mapButton.classList.remove('is--active');
                    mapButton.classList.add('is--disabled');
                }
            } else {
                mapButton.classList.remove('is--active');
                mapButton.classList.remove('is--disabled');
            }
            
            if(slug) {
                let mapPath = mapSvg.querySelector('#' + slug);

                if(mapPath) {
                    if(activeButtonSlug) {
                        if(activeButtonSlug === slug) {
                            mapPath.classList.add('is--active');
                        } else {
                            mapPath.classList.remove('is--active');
                        }
                    } else {
                        mapPath.classList.remove('is--active');
                    }
                }
            }
        });

        if(locations) {
            activeButton ? locations.classList.add('is--disabled') : locations.classList.remove('is--disabled');
        }
    }
}

function setActiveTab(tabContainer, index) {
    let tabsContent = tabContainer.querySelector('.tabs-content');

    if(tabsContent) {
        let tabs = tabContainer.querySelectorAll('.tab');
        let tabNavigationItems = tabContainer.querySelectorAll('.tab-navigation li');
        if(tabs.length > 0) {
            let activeIndex = 0;
            let valid = true;
            let multistep = tabContainer.classList.contains('is--multistep');

            tabs.forEach(tab => {
                tab.classList.contains('is--active') ? activeIndex = Array.from(tabs).indexOf(tab) : null;
            });

            if(typeof index === 'string') {
                if(multistep === true && index === 'next') {
                    let requiredFormItems = tabs[activeIndex].querySelectorAll('.form-item.is--required');

                    requiredFormItems.forEach(requiredFormItem => {
                        validateFormItem(requiredFormItem) === false ? valid = false : null;
                    });
                }

                if(valid === true) {
                    index === 'prev' ? activeIndex-- : activeIndex++;
                }
            } else {
                activeIndex = index;
            }

            if(multistep === true) {
                if(activeIndex < 0) {
                    activeIndex = 0;
                } else if(activeIndex >= tabs.length) {
                    activeIndex = tabs.length - 1;
                }
            } else {
                if(activeIndex < 0) {
                    activeIndex = tabs.length - 1;
                } else if(activeIndex >= tabs.length) {
                    activeIndex = 0;
                }
            }

            tabs.forEach((tab, i) => {
                if(i === activeIndex) {
                    tab.classList.add('is--active');
                    tab.removeAttribute('hidden');
                } else {
                    tab.classList.remove('is--active');
                    tab.setAttribute('hidden', true);
                }
            });

            tabNavigationItems.forEach((tabNavigationItem, i) => {
                let tabNavigationLink = tabNavigationItem.querySelector('a');

                if(i === activeIndex) {
                    tabNavigationItem.classList.add('is--active');
                } else {
                    tabNavigationItem.classList.remove('is--active');
                }

                if(multistep === true) {
                    if(i <= activeIndex) {
                        tabNavigationItem.classList.remove('is--disabled');
                    }
                }

                tabNavigationLink.setAttribute('aria-selected', i === activeIndex ? 'true' : 'false');
            });

            tabsContent.style.transform = 'translateX(-' + activeIndex * 100 + '%)';
        }
    }
}

function checkScroll() {
    let header = document.querySelector('header');

    if(header) {
        if(window.scrollY > 0) {
            header.classList.add('is--scrolled');
        } else {
            header.classList.remove('is--scrolled');
        }
    }
}

function formItemHasValue(formItem) {
    let hasValue = false;

    if(['checkbox', 'radio'].some(type => formItem.classList.contains('is--' + type))) {
        let formItemHolders = formItem.querySelectorAll('.form-item-holder');

        formItemHolders.forEach(formItemHolder => {
            let input = formItemHolder.querySelector('input');

            if(input && input.checked) {
                formItemHolder.classList.add('has--value');
                hasValue = true;
            } else {
                formItemHolder.classList.remove('has--value');
            }
        });
    } else {
        let formItemHolder = formItem.querySelector('.form-item-holder');
        
        if(formItemHolder) {
            let input = formItem.querySelector('input, textarea, select');
            
            if(input && input.value.length > 0) {
                formItemHolder.classList.add('has--value');
                hasValue = true;
            } else {
                formItemHolder.classList.remove('has--value');
            }
        }
    }

    return hasValue;
}

function validateFormItem(formItem) {
    let state = 'success';
    let message = '';
    let stateHolders = [];
    let form = document.querySelector('#contact-request-form')
    if(['checkbox', 'radio'].some(type => formItem.classList.contains('is--' + type))) {
        let formItemHolders = formItem.querySelectorAll('.form-item-holder');
        let inputs = formItem.querySelectorAll('input');

        if(Array.from(inputs).every(input => input.checked === false)) {
            stateHolders = Array.from(formItemHolders);
            message = form.dataset.error1;
            state = 'error';
        } else if(Array.from(inputs).some(input => input.checked === false && input.required === true)) {
            message = form.dataset.error2;
            state = 'error';

            formItemHolders.forEach(formItemHolder => {
                let input = formItem.querySelector('input');
    
                input && input.checked === false && input.required === true ? stateHolders.push(formItemHolder) : null;
            });
        }
    } else {
        let formItemHolder = formItem.querySelector('.form-item-holder');

        if(formItemHolder) {
            let input = formItemHolder.querySelector('input, textarea, select');

            if(input) {
                if(formItem.classList.contains('is--plz')) {
                    const country = document.querySelector('#input-country').value
                    if (country === "DE" && (!input.value || input.value.length != 5)){
                        message = form.dataset.error3;
                        state = 'error';
                    }
                }else if(formItem.classList.contains('is--email')) {
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (!input.value || !emailRegex.test(input.value)){
                        message = form.dataset.error3;
                        state = 'error';
                    }
                } else if(formItem.classList.contains('is--number')) {
                    let value = input.value ? parseFloat(input.value) : 0;
                    let min = input.min ? parseFloat(input.min) : false;
                    let max = input.max ? parseFloat(input.max) : false;

                    if(!input.value) {
                        message = form.dataset.error3;
                        state = 'error';
                    } else if(!input.value.match(/^-?\d+$/g)) {
                        input.value = input.value.replace(/(?!^-)[^\d]/g, '');
                        message = form.dataset.error3;
                        state = 'error';
                    } else if(min && value < min) {
                        input.value = min;
                        message = form.dataset.error3;
                        state = 'error';
                    } else if(max && value > max) {
                        input.value = max;
                        message = form.dataset.error3;
                        state = 'error';
                    }
                } else {
                    if(!input.value) {
                        message = form.dataset.error3;
                        state = 'error';
                    }
                }
            }

            stateHolders.push(formItemHolder);
        }
    }
    
    setFormItemState(formItem, stateHolders, message, state);
    
    return state === 'success';
}

function setFormItemState(formItem, stateHolders, message = '', state = 'error') {
    let formItemMessage = formItem.querySelector('.form-item-message');
    let formItemHolders = formItem.querySelectorAll('.form-item-holder');

    if(formItemMessage) {
        formItemMessage.innerHTML = message;

        message ? formItemMessage.classList.remove('is--hidden') : formItemMessage.classList.add('is--hidden')
    }

    formItemHolders.forEach(formItemHolder => {
        ['error', 'warning', 'success'].forEach(oldState => {
            formItemHolder.classList.remove('is--' + oldState);
        });
    });
    
    stateHolders.forEach(stateHolder => {
        stateHolder.classList.add('is--' + state);
    });
}

function updateMarqueesDuration(marquees) {
    marquees.forEach(marquee => {
        let content = marquee.querySelector('.marquee-content');
    
        if(content) {
            content.style.animationDuration = content.scrollWidth / window.innerWidth * 15 + 's';
        }
    });
}

export default function() {
    let marquees = document.querySelectorAll('.marquee');
    let sliders = document.querySelectorAll('.slider');
    let interactionToggleElements = document.querySelectorAll('.interaction--toggle');
    let formItems = document.querySelectorAll('.form-item');
    let forms = document.querySelectorAll('form');
    let tabContainers = document.querySelectorAll('.tabs');
    let activeForms = document.querySelectorAll('.init-check');
    
    document.body.classList.add('js--enabled');

    activeForms.forEach(form => {
        formItems.forEach(form => {
            validateFormItem(form)
        })
    })

    if(marquees.length > 0) {
        updateMarqueesDuration(marquees);

        window.addEventListener('resize', () => {
            updateMarqueesDuration(marquees);
        });
    }

    sliders.forEach(slider => {
        let swiperWrapper = false;
        let prevButton = false;
        let nextButton = false;
        let pagination = false;
        let gallery = slider.closest('.gallery') ? true : false;
        
        for (let i = 0; i < slider.children.length; i++) {
            let child = slider.children[i];

            if(child.classList.contains('is--prev')) {
                prevButton = child;
            } else if(child.classList.contains('is--next')) {
                nextButton = child;
            } else if(child.classList.contains('swiper-pagination')) {
                pagination = { el: child };
            } else if(child.classList.contains('swiper-wrapper')) {
                swiperWrapper = child;
            }
        }

        if(swiperWrapper && swiperWrapper.childNodes.length > 0) {
            gallery === true ? slider.elements = [] : null;

            for (let i = 0; i < swiperWrapper.children.length; i++) {
                let swiperSlide = swiperWrapper.children[i];
                swiperSlide.classList.add('swiper-slide');
                gallery === true ? slider.elements.push(swiperSlide) : null;
                
            }

            let swiperWrapperComputedStyle = getComputedStyle(swiperWrapper);
            let columnsMobile = swiperWrapperComputedStyle.getPropertyValue('--columns-mobile') ? parseInt(swiperWrapperComputedStyle.getPropertyValue('--columns-mobile').trim()) : 1;
            let columnsTablet = swiperWrapperComputedStyle.getPropertyValue('--columns-tablet') ? parseInt(swiperWrapperComputedStyle.getPropertyValue('--columns-tablet').trim()) : 2;
            let columnsDesktop = swiperWrapperComputedStyle.getPropertyValue('--columns-desktop') ? parseInt(swiperWrapperComputedStyle.getPropertyValue('--columns-desktop').trim()) : 3;
            let gapMobile = swiperWrapperComputedStyle.getPropertyValue('--gap-mobile') ? parseInt(swiperWrapperComputedStyle.getPropertyValue('--gap-mobile').trim()) : 16;
            let gapTablet = swiperWrapperComputedStyle.getPropertyValue('--gap-tablet') ? parseInt(swiperWrapperComputedStyle.getPropertyValue('--gap-tablet').trim()) : 24;
            let gapDesktop = swiperWrapperComputedStyle.getPropertyValue('--gap-desktop') ? parseInt(swiperWrapperComputedStyle.getPropertyValue('--gap-desktop').trim()) : 32;

            let navigation = !prevButton && !nextButton ? false : { prevEl: prevButton, nextEl: nextButton };
            let rewind = slider.classList.contains('is--loop');
            let autoplay = slider.dataset.autoplay ? { delay: parseInt(slider.dataset.autoplay) } : false;

            let options = {
                slidesPerView: columnsMobile,
                spaceBetween: gapMobile,
                watchSlidesProgress: true,
                pagination,
                navigation,
                rewind,
                autoplay,
                breakpoints: {
                    640: {
                        slidesPerView: columnsTablet,
                        spaceBetween: gapTablet,
                    },
                    1024: {
                        slidesPerView: columnsDesktop,
                        spaceBetween: gapDesktop,
                    },
                },
                keyboard: {
                    enabled: true
                }
            };

            if(gallery === true) {
                options.grid = {
                    fill: 'row',
                    rows: 2
                };
            }

            let mapContainer = slider.closest('.map-container');

            if(mapContainer) {
                options.on = {
                    slideChange: (e) => {
                        let mapButton = e.slides[e.activeIndex].querySelector('[data-location]');

                        if(mapButton) {
                            setActiveLocation(mapContainer, mapButton);
                        }
                    }
                }
            }
            
            slider.swiper = new Swiper(slider, options);
        }
    });


    formItems.forEach(formItem => {
        let input = formItem.querySelector('input, textarea, select');

        if(input) {
            input.type === 'number' ? input.type = 'text' : null;

            if(input.value && formItem.classList.contains('is--required') && ['checkbox', 'radio'].every(type => !formItem.classList.contains('is--' + type))) {
                validateFormItem(formItem);
            }
        }

        formItemHasValue(formItem);
    });

    forms.forEach(form => {
        form.setAttribute('novalidate', true);
    });

    tabContainers.forEach(tabContainer => {
        let tabs = tabContainer.querySelectorAll('.tab');
        let multistep = tabContainer.classList.contains('is--multistep');

        let tabNavigation = document.createElement('ol');
        tabNavigation.classList.add('tab-navigation');
        tabNavigation.setAttribute('role', 'tablist');
        tabs.forEach((tab, i) => {
            let tabIconText = tab.querySelector('.icon-text');
            let tabNavigationItem = document.createElement('li');
            let tabNavigationLink = document.createElement('a');

            let tabHeadline = null;
            let tabIcon = null;

            tabNavigationItem.setAttribute('role', 'presentation');
            tab.setAttribute('role', 'tabpanel');
            tab.setAttribute('tabindex', 0);
            
            if(i === 0) {
                tabNavigationItem.classList.add('is--active');
                tab.classList.add('is--active');
            } else {
                tab.setAttribute('hidden', true);
                multistep === true ? tabNavigationItem.classList.add('is--disabled') : null;
            }

            tabNavigationLink.setAttribute('role', 'tab');
            tabNavigationLink.setAttribute('aria-controls', tab.id);
            tabNavigationLink.setAttribute('href', '#' + tab.id);
            tabNavigationLink.setAttribute('aria-selected', i === 0 ? 'true' : 'false');

            if(tabIconText) {
                let tabHeadlineContent = tabIconText.querySelector('.tab-headline');
                let tabIconContent = tabIconText.querySelector('.icon');
                
                tabHeadlineContent ? tabHeadline = tabHeadlineContent.innerHTML : null;
                tabIconContent ? tabIcon = tabIconContent : null;

                tabIconText.classList.forEach(classItem => {
                    classItem !== 'icon-text' ? tabNavigationItem.classList.add(classItem) : null;
                });

                tabIconText.remove();
            }

            tabIcon ? tabNavigationLink.appendChild(tabIcon) : null;

            if(tabHeadline) {
                let tabHeadlineSpan = document.createElement('span');
                tabHeadlineSpan.innerHTML = tabHeadline;
                tabNavigationLink.appendChild(tabHeadlineSpan);
            }
            
            tabNavigationItem.appendChild(tabNavigationLink);
            tabNavigation.appendChild(tabNavigationItem);
        });
        if (!tabContainer.classList.contains('is--initialized')){
            tabContainer.prepend(tabNavigation);
            tabContainer.classList.add('is--initialized');
        }
    });

    checkScroll();

    let lightboxElements = document.querySelectorAll('.interaction--enlarge');
    var galleryContainerLightboxes = [];
    let settings = {
        history: false,
        captionSelector: 'self',
        navText: [
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M47 239c-9.4 9.4-9.4 24.6 0 33.9L207 433c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L97.9 256 241 113c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L47 239z"/></svg>',
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M273 239c9.4 9.4 9.4 24.6 0 33.9L113 433c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l143-143L79 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L273 239z"/></svg>'
        ],
        closeText: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"/></svg>',
    };
    
    lightboxElements.forEach(lightboxElement => {
        let galleryContainer = lightboxElement.closest('.gallery, .slider');

        if(galleryContainer) {
            !galleryContainerLightboxes.includes(galleryContainer) ? galleryContainerLightboxes.push(galleryContainer) : null;
        } else {
            lightboxElement.lightbox = new SimpleLightbox(lightboxElement, settings);
        }
    });

    if(galleryContainerLightboxes.length > 0) {
        galleryContainerLightboxes.forEach(galleryContainerLightbox => {
            let galleryItems = galleryContainerLightbox.querySelectorAll('.interaction--enlarge');

            galleryContainerLightbox.lightbox = new SimpleLightbox(galleryItems, settings);
            Array.from(galleryItems).every(galleryItem => { galleryItem.lightbox = galleryContainerLightbox.lightbox; });
        });
    }

    window.addEventListener('click', (e) => {
        let interactionToggleElement = e.target.closest('.interaction--toggle');
        let nav = e.target.closest('nav');
        let interactionNavigationElement = e.target.closest('.interaction--navigation');
        let formItem = e.target.closest('.form-item');
        let tabNavigationLink = e.target.closest('.tab-navigation a');
        let gallery = e.target.closest('.gallery');
        let selectAll = e.target.closest('[data-select-all]');

        if(interactionToggleElement || nav) {
            if(interactionToggleElement) {
                let targets = interactionToggleElement.dataset.toggle ? document.querySelectorAll(interactionToggleElement.dataset.toggle) : false;

                targets.forEach(target => {
                    target.classList.toggle('is--hidden');
                });
            }

            if(interactionToggleElement && interactionToggleElement.id === 'open-menu' || nav) {
                let header = document.querySelector('header');

                if(header) {
                    header.classList.toggle('menu--open');
                }
            }
        } else if(interactionNavigationElement) {
            let direction = interactionNavigationElement.classList.contains('is--next') ? 'next' : 'prev';

            if(direction) {
                let tabs = e.target.closest('.tabs');

                if(tabs) {
                    setActiveTab(tabs, direction);
                } else if(!e.target.closest('.slider') && !e.target.closest('.gallery')) {
                    direction === 'next' ? window.history.forward() : window.history.back();
                }
            }
            
        } else if(formItem && formItem.classList.contains('is--number')) {
            let button = e.target.closest('.button');

            if(button && ['plus', 'minus'].some(classItem => button.classList.contains('is--' + classItem))) {
                let input = formItem.querySelector('input');

                if(input) {
                    let value = input.value ? parseFloat(input.value) : 0;
                    let step = input.step ? parseFloat(input.step) : 1;

                    if(button.classList.contains('is--minus')) {
                        value = value - step;
                    } else {
                        value = value + step;
                    }

                    input.value = value;
                    input.dispatchEvent(new Event('input', { bubbles: true }));
                }
            }
        } else if(tabNavigationLink) {
            let tabs = e.target.closest('.tabs');

            e.preventDefault();

            if(tabs) {
                let tabNavigationLinks = tabs.querySelectorAll('.tab-navigation a');

                if(tabNavigationLinks.length > 0) {
                    let index = Array.from(tabNavigationLinks).indexOf(tabNavigationLink);

                    setActiveTab(tabs, index);
                }
            }
        } else if(gallery) {
            let slider = gallery.querySelector('.slider');
            let clickedFilterButton = e.target.closest('.filter .button');

            if(slider && slider.swiper && clickedFilterButton) {
                let filterButtons = gallery.querySelectorAll('.filter .button');
                let filter = clickedFilterButton.dataset.location || 'all';
                
                filterButtons.forEach(filterButton => {
                    filterButton === clickedFilterButton ? filterButton.classList.add('is--active') : filterButton.classList.remove('is--active');
                });

                if(filter) {
                    slider.swiper.removeAllSlides();

                    slider.elements.forEach(slide => {
                        let image = slide.querySelector('.image');

                        if(image) {
                            let slideFilter = image.dataset.location || false;
                    
                            if(filter === 'all' || slideFilter === filter) {
                                slider.swiper.appendSlide(slide);
                            }
                        }
                    });
                }
            }
            
        } else if(selectAll) {
            let selectAllTarget = selectAll.dataset.selectAll ? document.querySelector(selectAll.dataset.selectAll) : false;

            if(selectAllTarget) {
                let inputs = selectAllTarget.querySelectorAll('input');
                let event = new Event('input', { bubbles: true });

                inputs.forEach(input => {
                    input.checked = true;
                    input.dispatchEvent(event);
                });
            }
        }
    })

    window.addEventListener('mouseover', (e) => {
        let mapContainer = e.target.closest('.map-container');
        let mapButton = e.target.closest('[data-location]');

        if(mapContainer) {
            let activeButton = mapButton || false;
            setActiveLocation(mapContainer, activeButton);
        } else {
            let mapContainers = document.querySelectorAll('.map-container');

            mapContainers.forEach(mapContainer => {
                setActiveLocation(mapContainer, false);
            });
        }
    })

    window.addEventListener('scroll', (e) => {
        checkScroll();
    })

    window.addEventListener('input', (e) => {
        let formItem = e.target.closest('.form-item');
        
        if(formItem) {
            let formItemHolder = formItem.querySelector('.form-item-holder');

            if(formItem.classList.contains('is--required')) {
                validateFormItem(formItem);
            }

            formItemHasValue(formItem);
            
            if(formItemHolder) {
                let input = formItem.querySelector('input, textarea, select');
                
                if(input && !['checkbox', 'radio'].includes(input.type)) {
                    if(input.value.length > 0) {
                        formItemHolder.classList.add('has--value');
                    } else {
                        formItemHolder.classList.remove('has--value');
                    }
                }
            }
        }
    })
};
